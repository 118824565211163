import {
  Form, Select,
} from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'
import classes from './Form.module.scss'
import classNames from 'classnames'
import { useMemo } from 'react'

const InlineSelect = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  maxLength = null,
  label,
  legend = null,
  required,
  error,
  silentRequired = false,
  initialValue,
  handleEffect,
  options,
  requiredLang,
  info,
  formError = false
}) => {
  const isError = useMemo(() => formError && error && required, [error, formError, required])
  return (
    <Form.Field
      required={required}
      className={classNames(classes.field, classes.flex)}
    >
      {label ?
        <label className={classes.conformLabel} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label> : null
      }
      <Select
        className={classNames(classes.myInput, isError && classes.error)}
        style={{
          width: 'auto',
          marginLeft: label ? '10px' : '0',
          marginRight: required && error && !silentRequired ? '10px' : '0',
        }}
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        onChange={handleEffect}
        options={options} />
      {legend ? <p className={classes.legendInput}>{legend}</p> : null}
      {info ? <InfoInput info={info} /> : null}
      {required && error && !silentRequired ?
        <Label
          style={{ display: 'inline-table' }}
          display={requiredLang}
          pointing={'left'}
          color={'red'} /> : null
      }
    </Form.Field>
  )
}

export default InlineSelect
