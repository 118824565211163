import { Form, Icon, Input, Label } from 'semantic-ui-react'
import InfoInput from './InfoInput'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ibanService from 'iban'
import classNames from 'classnames'
import classes from './Form.module.scss'

const IbanInput = ({
    onKeyPress = () => { },
    type,
    name,
    onChange,
    value,
    initialValue,
    error,
    labelObject,
    styles = { container: '' },
    placeholder,
    required,
    silentRequired = false,
    toCheck = false,
    disabled = false,
    options = [],
    maxLength = null,
    fluid = false,
    legend = null,
    inline = true,
    responsive = true,
    style = {},
    labelStyle = {},
    centered = false,
    info = null,
    label,
    handleEffect,
    requiredLang,
    onBlur = null,
    forwardRef = null,
    children,
    onFocus = () => { },
    errorLogic,
    preview,
    formError = false
}) => {
    const isError = useMemo(() => formError && error && required, [error, formError, required])

    const [ibanFormatting, setIbanFormatting] = useState('')
    const [isValid, setIsValid] = useState(ibanService.isValid(value || ''))
    const toIbanFormat = useCallback(ibanStr => ibanStr.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim(), [])

    useEffect(() => {
        if (value && (ibanFormatting == null || ibanFormatting === '')) {
            setIbanFormatting(toIbanFormat(value))
        }
    }, [value, toIbanFormat])

    const onUpdate = ({ target: { value: newValue } }) => {
        const ibanDisplay = toIbanFormat(newValue)
        setIbanFormatting(ibanDisplay)
        const isValidIban = ibanService.isValid(ibanDisplay)
        setIsValid(isValidIban)
        handleEffect({ target: { value: isValidIban ? newValue.replace(/\s/g, '') : '' } })
    }

    return (
        <Form.Field
            required={required}
            style={styles.container || {}}
            className={classNames(
                classes.field,
                styles.container,
                responsive
                    ? centered
                        ? classes.horizontalCenteredContainer
                        : classes.flex
                    : '')}
            inline={inline}
        >
            {label ? (
                <label className={classes.conformLabel} style={labelStyle} htmlFor={name + (initialValue ? `-${initialValue}` : '')}>
                    {label}
                </label>
            ) : null}
            <>
                <div>{ibanFormatting}</div>
                <Input
                    {...(forwardRef ? { ref: forwardRef } : {})}
                    className={classNames(classes.hFit, classes.myInputIsChild, isError && classes.error)}
                    onKeyPress={onKeyPress}
                    id={name + (initialValue ? `-${initialValue}` : '')}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onChange={onUpdate}
                    onBlur={onBlur || onUpdate}
                    fluid={fluid}
                    style={style}
                    disabled={disabled}
                    onFocus={onFocus}
                    {...(maxLength ? { maxLength } : {})}
                    icon={<Icon name={isValid ? 'check' : 'close'} color={isValid ? 'green' : 'red'} />}
                    iconPosition="left"
                />
            </>
            {legend ? (
                <div>
                    <p className={classes.legendInput}>{legend}</p>
                </div>
            ) : null}
            {info ? <InfoInput info={info} /> : null}
            {children}
            {errorLogic || (required && error && !silentRequired) ? (
                <Label
                    style={{
                        display: 'inline-table',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                    }}
                    pointing="left"
                    color="red"
                    basic
                >
                    {errorLogic || requiredLang}
                </Label>
            ) : null}
        </Form.Field>
    )
}

export default IbanInput
