import { Form } from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useEffect, useMemo, useState } from 'react';
import classes from './Form.module.scss'
import classNames from 'classnames';

const filter = createFilterOptions();

const FormAutocomplete = ({
    id,
    name,
    placeholder,
    onChange,
    onBlur,
    maxLength = null,
    label,
    legend = null,
    required,
    error,
    silentRequired = false,
    initialValue,
    handleEffect,
    options: optionsFromParams = [], //[value]
    requiredLang,
    info,
    formError = false
}) => {
    const [value, setValue] = useState(initialValue || '')

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue || '')
        }
    }, [initialValue])

    const options = useMemo(() => optionsFromParams.map(option => ({ title: option })), [optionsFromParams])

    const onValueChange = (event, newValue) => {
        let valueToSend = null
        if (typeof newValue === 'string') {
            valueToSend = newValue
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            valueToSend = newValue.inputValue
        } else if (newValue && newValue.title) {
            // Create a new value from the user input
            valueToSend = newValue.title
        } else {
            valueToSend = newValue
        }
        setValue({ title: valueToSend })
        return handleEffect({
            target: { value: valueToSend || '' }
        })
    }
    const isError = useMemo(() => formError && error && required, [error, formError, required])

    return (
        <Form.Field
            required={required}
            className={classNames(classes.field, classes.flex)}
        >
            {label ?
                <label className={classes.conformLabel} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
                    {label}
                </label> : null
            }

            <Autocomplete
                className={classNames(classes.myInputIsChild, isError && classes.error)}
                value={value}
                onChange={onValueChange}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            title: `→ "${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id={name + (initialValue ? '-' + initialValue : '')}
                name={name}
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option || '';
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue || '';
                    }
                    // Regular option
                    return option.title || '';
                }}
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} /*label={placeholder || ''}*/ />
                )}
            />

            {legend ? <p className={classes.legendInput}>{legend}</p> : null}
            {info ? <InfoInput info={info} /> : null}
            {required && error && !silentRequired ?
                <Label
                    style={{ display: 'inline-table' }}
                    display={requiredLang}
                    pointing={'left'}
                    color={'red'} /> : null
            }
        </Form.Field>
    )
}

export default FormAutocomplete
