import React, { useMemo } from 'react';
import classes from './Button.module.scss'
import { Button as SemanticButton } from 'semantic-ui-react'
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Button = ({
    type: typeFromParams = 'primary',
    className = '',
    link = null,
    external = false,
    disabled = false,
    ...props
}) => {
    const linkProperties = useMemo(() => link ? external ? {
        target: '_blank',
        rel: 'noreferrer',
        as: 'a',
        href: link
    } : {
        as: Link,
        to: link,
    } : {}
        , [link, external])
    const type = useMemo(() => typeFromParams === 'submit' ? 'primary' : typeFromParams, [typeFromParams])
    return (
        <SemanticButton
            {...linkProperties}
            className={classNames(
                classes.default,
                classes[type] && classes[type],
                disabled && classes.disabled,
                className
            )}
            disabled={disabled}
            {...(typeFromParams === 'submit' ? { type: typeFromParams } : {})}
            {...props} />
    );
};

export default Button;