import { Label } from 'semantic-ui-react'
import classes from './Label.module.scss'

export default ({
  display, pointing, color = 'black', style = {},
}) => (
  <Label
    style={style}
    basic
    className={classes.root}
    pointing={pointing}>
    {display}
  </Label>
)
