import classNames from 'classnames'
import { Icon } from 'semantic-ui-react'
import classes from './Form.module.scss'

const InfoInput = ({
  info = '',
  infoClassName = '',
  className = '',
  size = 'large',
}) => {
  return (
    <div className={classNames(classes.questionFormContainer, className)}>
      <Icon size={size} name="question circle outline" />
      <p className={classNames(classes.info, classes.hidden, infoClassName)}>{info}</p>
    </div>
  )
}

export default InfoInput
