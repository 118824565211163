import React, { useRef, useState } from 'react'
import {
	Button,
} from 'semantic-ui-react'
import Compressor from 'compressorjs';

const acceptedTypes = [
	'image/*',
	'image/png',
	'image/x-png',
	'image/jpeg',
	'image/jpg',
	'image/JPG',
	'image/gif',
].join(',')

const ImageInput = ({
	onChange = () => { },
	fileErrorLang,
	fileLang,
	initialValue,
	name,
}) => {
	const [loading, setLoading] = useState(false)
	const fileInputRef = useRef()
	const [fileName, setFileName] = useState(null)
	const [sizeTooLarge, setSizeTooLarge] = useState(false)
	const [fileSize, setFileSize] = useState(0)

	const getBase64 = (file, cb) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			cb(reader.result)
			setFileName(file.name)
			sizeTooLarge && setSizeTooLarge(false)
		}
		reader.onerror = function (error) {
			process.env.NODE_ENV !== 'production' && console.error('Error: ', error)
		}
	}

	const uploadImage = evt => {
		setLoading(true)
		const file = evt.target.files[0]
		if (!file) { return }
		if (file.size <= 3500000) { // >3.5MO
			setFileName(file.name)
			setSizeTooLarge(false)
			// const base64Image = image => this.props.onChange({ target: { value: image } })
			onChange({ target: { value: file } })
			setLoading(false)
			return URL.createObjectURL(file)
		}

		new Compressor(file, {
			quality: 0.6,

			// The compression process is asynchronous,
			// which means you have to access the `result` in the `success` hook function.
			success(blob) {
				const compressedFile = new File([blob], file.name, { type: file.type })
				if (compressedFile.size > 3500000) { // >3.5MO
					setFileSize(file.size)
					setLoading(false)

					return setSizeTooLarge(true)
				}
				setFileName(compressedFile.name)
				setSizeTooLarge(false)
				// const base64Image = image => this.props.onChange({ target: { value: image } })
				onChange({ target: { value: compressedFile } })
				setLoading(false)
				return URL.createObjectURL(compressedFile)
			},
			error(err) {
				setLoading(false)
				console.log(err.message);
			},
		});
		// return this.getBase64(file, base64Image)
	}

	return (
		<>
			<Button
				type="button"
				content={
					sizeTooLarge ? fileErrorLang + `(${Math.round(fileSize/ 1000000)}MB)` :
						fileName || fileLang + ' (< 3.5MB)'}
				color={sizeTooLarge ? 'red' : 'purple'}
				labelPosition="left"
				icon="file"
				onClick={() => fileInputRef.current.click()}
				loading={loading}
			/>
			<input
				style={{ display: 'none' }}
				id={name + (initialValue ? '-' + initialValue : '')}
				name={name}
				ref={fileInputRef}
				type="file"
				accept={acceptedTypes}
				hidden
				onChange={uploadImage}
				onBlur={uploadImage}
			/>
		</>
	)

}

export default ImageInput