import { useState } from 'react'
import { Form } from 'semantic-ui-react'
import Radio from './Radio'

export default ({
  label,
  name,
  value: initialValue,
  radios, // changer pour choices
  required,
  onChange,
  formError
}) => {
  const [val, setVal] = useState(required && !initialValue ? radios[0].label : initialValue)

  const isLabelOnly = value => !(radios.find(({ label }) => value === label) || {}).under

  const handleChange = value => {
    setVal(value)
    onChange({
      label, name, value: isLabelOnly(value) ? value : null,
    })
  }

  const isChecked = ({ label, under = [] }) => !!(val && (
    val.includes(label) ||
    !!under.find(isChecked)
  ))

  return (
    <Form.Group required={required} inline>
      <label>{label}</label>
      {radios.map(({
        label, value, under, next,
      }, index) => <Radio
          key={index + name}
          type={'radio'}
          name={name}
          label={label}
          value={value}
          toCheck={isChecked({ label, under })}
          isChecked={isChecked}
          handleChange={handleChange}
          under={under}
          next={next}
        />)}
    </Form.Group>
  )
}
