import Button from 'components/Button/Button'
import classes from './ButtonSendForm.module.scss'

const ButtonSendForm = ({
  button,
  onSubmit,
  messages,
  error,
  responsive,
  emptyFields = [],
  submitLang,
  loading = false,
  disabled = false,
}) => {
  return (
    <div className={classes.root}>
      {/* {error && responsive && <MyMessage
        header='Champs manquants'
        content={() => <div className="empty-fields-responsive">{emptyFields.map(
          (field, index) => <span key={index}>{field}</span>
		  )}</div>}
      /> } */}
      <Button loading={loading} 
        disabled={loading}

      // color={button.color}
       as='a' onClick={onSubmit} >
        {messages.submit || submitLang}
        {/* <Button.Content hidden>
          <Icon name='arrow right' />
        </Button.Content> */}
      </Button>
    </div>
  )
}

export default ButtonSendForm
