import { useState } from 'react'
import { Form } from 'semantic-ui-react'
import Input from './SpecialInput'
import classes from './Form.module.scss'
export default ({
  isChecked,
  under = [],
  handleChange = () => void 0,
  toCheck = false,
  label,
  ...props
}) => {
  const disabled = !isChecked({ label, under })
  const [val, setVal] = useState([label])

  const onChange = ({ label, type }) => {
    const newVal = type === 'checkbox' ? (
      val.indexOf(label) === -1 ? [...val, label].filter(c => c) :
      val.filter(elem => elem !== label)
    ) : [label]

    handleChange(
      newVal.length > 1 ? [newVal[0] + ': ' + newVal[1], ...newVal.slice(2)].join(', ') : newVal[0]
    )
    setVal(newVal)
  }

  return under.length > 0 ? <Form.Field className={classes.field}>
    <Input {...props} label={label} onChange={onChange} toCheck={toCheck}/>
    {under.map(
      (props, index) =>
        <Input
          {...props}
          key={index}
          disabled={disabled}
          onChange={onChange}
        />)}
  </Form.Field> : <Input {...props} label={label} toCheck={toCheck} onChange={onChange}/>
}
