import classNames from 'classnames'
import { useMemo, useState } from 'react'
import {
  Form, Input, Label,
} from 'semantic-ui-react'
import ImageInput from './ImageInput'
import InfoInput from './InfoInput'
import InvalidEmailComponent from './InvalidEmailComponent'
import classes from './Form.module.scss'
import { isUndefined } from './utils'

const InputEmail = ({
  onKeyPress = () => { },
  type,
  name,
  onChange,
  value,
  initialValue,
  error,
  labelObject,
  styles = { container: '' },
  placeholder,
  required,
  silentRequired = false,
  toCheck = false,
  disabled,
  options = [],
  maxLength = null,
  fluid = false,
  legend = null,
  inline = true,
  responsive = true,
  style = {},
  labelStyle = {},
  centered = false,
  info = null,
  label,
  handleEffect,
  requiredLang,
  fileLang,
  fileErrorLang,
  onBlur = null,
  forwardRef = null,
  children,
  onFocus = () => { },
  formError = false
}) => {
  const [email, setEmail] = useState('')
  const isError = useMemo(() => formError && error && required, [error, formError, required])

  const handleInputEmail = event => {
    setEmail(event.target.value)
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
      handleEffect(event, { value: event.target.value })
    } else {
      handleEffect({ target: { value: '' } }, { value: '' })
    }
  }
  return (
    <Form.Field
      required={required}
      className={classNames(styles.container,
        classes.field,
        styles.container,
        responsive
          ? centered
            ? classes.horizontalCenteredContainer
            : classes.flex
          : '')}
      inline={inline} >
      {label ?
        <label className={classes.conformLabel} style={labelStyle} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label> : null
      }
      {type === 'image' ? <ImageInput
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        onChange={handleEffect}
        // onBlur={handleEffect}
        fileLang={fileLang}
        fileErrorLang={fileErrorLang}
      /> :
        <Input
          {...(forwardRef ? { ref: forwardRef } : {})}
          className={classNames( classes.myInputIsChild, isError && classes.error)}
          onKeyPress={onKeyPress}
          id={name + (initialValue ? '-' + initialValue : '')}
          name={name}
          type={type}
          value={email}
          placeholder={placeholder}
          onChange={handleInputEmail}
          onBlur={handleInputEmail}
          fluid={fluid}
          style={style}
          onFocus={handleInputEmail}

          {...(maxLength ? { maxLength } : {})}
        />
      }
      {isError && !isUndefined(required, email) && <InvalidEmailComponent />}
      {legend ? <div><p className={classes.legendInput}>{legend}</p></div> : null}
      {info ? <InfoInput info={info} /> : null}
      {children}
      {required && error && !silentRequired ?
        <Label style={{
          display: 'inline-table',
          marginTop: 'auto',
          marginBottom: 'auto',
        }} pointing={'left'} color={'red'} basic >
          {requiredLang}
        </Label> : null
      }
    </Form.Field>
  )
}

export default InputEmail
