import { useState, useEffect, useMemo, useCallback } from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import InfoInput from './InfoInput'
import classes from './Form.module.scss'
import classNames from 'classnames'

export default ({
  value = '',
  label = null,
  checked = false,
  disabled = false,
  onChange = () => void 0,
  required,
  styles = { container: '' },
  responsive,
  centered,
  inline,
  labelStyle,
  name,
  labelRight,
  initialValue,
  info,
  className = '',
  formError = false,
  error = false

}) => {
  const [check, setCheck] = useState(disabled ? false : checked)
  useEffect(() => {
    setCheck(disabled ? false : checked)
  }, [disabled])

  const handleEffect = useCallback(event => {
    if (!disabled) {
      onChange({ target: { value: !check } }, {
        checked: !check,
        label,
        value: !check,
      })
      setCheck(!check)
    }
  }, [check, disabled, label, onChange])
  const isError = useMemo(() => formError && error && required, [error, formError, required])
  const labelComponent = useMemo(() => {
    if (typeof label === 'string') {
      return <label
        className={classNames(classes.conformLabel, isError && classes.error)}
        style={labelStyle}
        htmlFor={name + (initialValue ? '-' + initialValue : '')}
      >
        {label}
      </label>
    } else {
      return label
    }
  }, [initialValue, label, labelStyle, name, isError])


  return <Form.Field
    required={required}
    className={classNames(
      styles.container,
      classes.field,
      responsive
        ? centered
          ? classes.horizontalCenteredContainer
          : classes.flex
        : '')}
    inline={inline} >
    {!!label && !labelRight && labelComponent}
    <Checkbox
      className={classNames(classes.checkbox, className, isError && classes.error)}
      value={value}
      disabled={disabled}
      checked={check}
      label={labelRight ? label : ''}
      onClick={handleEffect}
    />
    {info && <InfoInput info={info} />}
  </Form.Field>
}
