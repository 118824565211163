import {
  TextArea, Form,
} from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'
import classes from './Form.module.scss'
import { useMemo } from 'react'
import classNames from 'classnames'

const InlineTextArea = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  maxLength = null,
  label,
  legend = null,
  required,
  error = false,
  silentRequired = false,
  initialValue,
  handleEffect,
  requiredLang,
  info,
  formError = false,
}) => {
  const isError = useMemo(() => formError && error && required, [error, formError, required])

  return (
    <Form.Field
      required={required}
      className={classes.field}
    >
      {label ? < >
        <label className={classes.conformLabel} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label>
        {required && error && !silentRequired ?
          <Label
            style={{ display: 'inline-table' }}
            display={requiredLang}
            pointing={'left'}
            color={'red'} /> : null
        }
      </> : null

      }

      <div className={classes.flex}>
        <TextArea
          className={classNames( classes.descriptionInput, classes.myInput, isError && classes.error)}
          id={name + (initialValue ? '-' + initialValue : '')}
          name={name}
          value={value || ''}
          placeholder={placeholder}
          onChange={handleEffect}
          onBlur={handleEffect}
          maxLength={maxLength} />
        {legend ? <p className={classes.legendInput}>{legend}</p> : null}
        {info ? <InfoInput info={info} /> : null}
      </div>
    </Form.Field>
  )
}

export default InlineTextArea
