import classes from 'styles/classes.module.scss'

const HorizontalCenteredGridFlexed = ({
  children,
}) => {
  return (
    <div className={classes.horizontalCenteredContainer}>{children}</div>
  )
}

export default HorizontalCenteredGridFlexed
