import { toast as _toast } from 'react-toastify'
import classes from './toast.module.scss'
import CheckIcon from 'icons/check.svg'
import ExclamationIcon from 'icons/exclamation.svg'
import './toast.css'

const ToastSuccess = ({ msg }) => (
	<div className={classes.wrapper}>
		<img className={classes.icon} src={CheckIcon} alt='check icon' />
		<span>{msg}</span>
	</div>
)

const ToastError = ({ msg }) => (
	<div className={classes.wrapper}>
		<img
			className={classes.icon}
			src={ExclamationIcon}
			alt='exclamation icon'
		/>
		<span>{msg}</span>
	</div>
)

function toast(msg) {
	_toast(<ToastSuccess msg={msg} />)
}

toast.success = (msg) => _toast(<ToastSuccess msg={msg} />)
toast.error = (msg) => _toast.error(<ToastError msg={msg} />, { icon: false })

export default toast
